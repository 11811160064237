<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:51:20
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="figurefive" v-if="you_xiaoping">
        <div class="xiaoping">
            <div class="xuanze_nianfen">
                <p>
                    年份:
                    <el-select v-model="time" @change='xuanze_nianfen_biao'>
                        <el-option
                            v-for="(i,index) in year_list"
                            :key="index"
                            :label="i"
                            :value="i">
                        </el-option>
                    </el-select>
                </p>
                <p>单位：元</p>
            </div>
            <img v-if="time==''" style="margin-top:0.1rem;height:86%;width:100%;" src="../../assets/shili_tu5.png" alt="">
            <ul v-if="time!=''" class="zhanshi_liebiao_2">
                <li><span></span>人均产值</li>
                <!-- <li><span></span>目标销售额</li> -->
                <li @click="renyuan_paixu">人员排序<img :src="paixu1=='unshift'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
                <li @click="siling_paixu">司龄排序<img :src="paixu2=='push'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
                <li @click="xiaoshou_paixu">目标销售额排序<img :src="paixu3=='unshift'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
            </ul>
            <ul v-if="time!=''" class="zhishi_liebiao">
                <li>部门</li>
                <li>人员</li>
                <li>司龄</li>
            </ul>
            <div v-if="time!=''" class="zhanshi_huabu">
                <div id="myCharts9" :style="{width:'100%',height:'75%',background:'#fff'}"></div>
            </div>
        </div>
        <div class="quanping" v-if="quan_zia">    <!--v-if="quan_zia==1"-->
            <div class="box">
                <div class="guanbi" @click="dianji_guanbi">X</div>
                <p class="box_text">人员销售目标(五)</p>
                <div class="box_con">
                    <div class="xuanze_nianfen">
                        <p>
                            年份:
                            <el-select v-model="time" @change='xuanze_nianfen_biao'>
                                <el-option
                                    v-for="(i,index) in year_list"
                                    :key="index"
                                    :label="i"
                                    :value="i">
                                </el-option>
                            </el-select>
                        </p>
                        <p>单位：元</p>
                    </div>
                    <div class="table_con">
                        <ul v-if="time!=''" class="zhanshi_liebiao_2">
                            <li><span></span>人均产值</li>
                            <!-- <li><span></span>目标销售额</li> -->
                            <li @click="renyuan_paixu">人员排序<img :src="paixu1=='unshift'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
                            <li @click="siling_paixu">司龄排序<img :src="paixu2=='push'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
                            <li @click="xiaoshou_paixu">目标销售额排序<img :src="paixu3=='unshift'?require('../../assets/xiao-da.png'):require('../../assets/da-xiao.png')" alt=""></li>
                        </ul>
                        <ul v-if="time!=''" class="zhishi_liebiao">
                            <li>部门</li>
                            <li>人员</li>
                            <li>司龄</li>
                        </ul>
                        <div v-if="time!=''" class="zhanshi_huabu2">
                            <div id="myCharts9_da" :style="{width:'100%',height:'96%',background:'#fff'}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import { generate_as_staff_aim, query_as_year_list } from '../../api/api.js'
export default {
  name: 'figurefive',
  data () {
    return {
      time: '',
      year_list: [],
      biaotou_list: [
      ],
      neirong_list: [],
      shifou_baocun: false,
      quan_zia: '',
      y_zhou: '',
      zhu1: [],
      zhu2: [],
      xinxi: '',
      paixu1: 'push',
      paixu2: 'push',
      paixu3: 'push',
      you_xiaoping: true
    }
  },
  computed: {
  },
  props: {
    //   tuchu_chuanshu:String,
    //   tu5_quanzi:String,
    //   required: true,
  },
  watch: {
    //   tu5_quanzi(val){
    //     console.log(val)
    //     this.quan_zia=val
    // },
    // quan_zia(){
    //     setTimeout(() => {
    //         this.tupian9_da()
    //     }, 0)
    // }
  },
  created () {
    this.jichu()
  },
  mounted () {
      
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '5'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date != []) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
          }
          date.forEach(it => {
            if (it == new Date().getFullYear()) {
              this.time = new Date().getFullYear() + '年'
              this.xuanze_nianfen_biao()
            }
          })
        } else if (res.data.code == 10238) {}
      })
    },
    // 选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      this.$emit('tu5_nianfen', this.time)
      this.chaxun_jiekou()
      // this.chanpin_liebiao_jiekou()
    },
    // 计算
    jisuan () {
    },
    chaxun_jiekou () {
      generate_as_staff_aim({
        data: {
          year: this.time.slice(0, 4),
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.xinxi = date
          const list = []
          const y_zhou = []
          const zhu1 = []
          const zhu2 = []
          const aa = this.$paixu(date, 'work_age')
          for (let i = 0; i < aa.length; i++) {
            if (aa[i].work_age == 1) {
              aa[i].siling = '1年以内'
            }
            if (aa[i].work_age == 2) {
              aa[i].siling = '1-3年'
            }
            if (aa[i].work_age == 3) {
              aa[i].siling = '3-5年'
            }
            if (aa[i].work_age == 4) {
              aa[i].siling = '5-8年'
            }
            if (aa[i].work_age == 5) {
              aa[i].siling = '8-10年'
            }
            if (aa[i].work_age == 6) {
              aa[i].siling = '10年以上'
            }
            list.push(aa[i])
            y_zhou.push(aa[i].active == 0 ? aa[i].dept_name + '         ' + aa[i].staff_name + ' (已离职)' + '         ' + aa[i].siling : aa[i].dept_name + '         ' + aa[i].staff_name + '         ' + aa[i].siling)
            zhu1.push(Number(aa[i].amt_aim_final).toFixed(0))
            zhu2.push(Number(aa[i].work_age_pov).toFixed(0))
          }
          this.y_zhou = y_zhou
          this.zhu1 = zhu1
          this.zhu2 = zhu2
          if (this.you_xiaoping) {
            this.tupian9()
            if (this.quan_zia) {
              this.tupian9_da()
            }
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 点击人员排序
    renyuan_paixu () {
      if (this.paixu1 == 'push') {
        this.paixu1 = 'unshift'
      } else if (this.paixu1 == 'unshift') {
        this.paixu1 = 'push'
      }
      const list = []
      const y_zhou = []
      const zhu1 = []
      const zhu2 = []
      const aa = this.$chinepaixu(this.xinxi, 'staff_name')
      for (let i = 0; i < aa.length; i++) {
        if (aa[i].work_age == 1) {
          aa[i].siling = '1年以内'
        }
        if (aa[i].work_age == 2) {
          aa[i].siling = '1-3年'
        }
        if (aa[i].work_age == 3) {
          aa[i].siling = '3-5年'
        }
        if (aa[i].work_age == 4) {
          aa[i].siling = '5-8年'
        }
        if (aa[i].work_age == 5) {
          aa[i].siling = '8-10年'
        }
        if (aa[i].work_age == 6) {
          aa[i].siling = '10年以上'
        }
        list[this.paixu1](aa[i])
        y_zhou[this.paixu1](aa[i].dept_name + '         ' + aa[i].staff_name + '         ' + aa[i].siling)
        zhu1[this.paixu1](Number(aa[i].amt_aim_final).toFixed(0))
        zhu2[this.paixu1](Number(aa[i].work_age_pov).toFixed(0))
      }
      this.y_zhou = y_zhou
      this.zhu1 = zhu1
      this.zhu2 = zhu2
      if (this.you_xiaoping) {}
      this.tupian9()
      if (this.quan_zia) {
        this.tupian9_da()
      }
    },
    // 点击司龄排序
    siling_paixu () {
      if (this.paixu2 == 'push') {
        this.paixu2 = 'unshift'
      } else if (this.paixu2 == 'unshift') {
        this.paixu2 = 'push'
      }
      const list = []
      const y_zhou = []
      const zhu1 = []
      const zhu2 = []
      const aa = this.$paixu(this.xinxi, 'work_age')
      for (let i = 0; i < aa.length; i++) {
        if (aa[i].work_age == 1) {
          aa[i].siling = '1年以内'
        }
        if (aa[i].work_age == 2) {
          aa[i].siling = '1-3年'
        }
        if (aa[i].work_age == 3) {
          aa[i].siling = '3-5年'
        }
        if (aa[i].work_age == 4) {
          aa[i].siling = '5-8年'
        }
        if (aa[i].work_age == 5) {
          aa[i].siling = '8-10年'
        }
        if (aa[i].work_age == 6) {
          aa[i].siling = '10年以上'
        }
        list[this.paixu2](aa[i])
        y_zhou[this.paixu2](aa[i].dept_name + '         ' + aa[i].staff_name + '         ' + aa[i].siling)
        zhu1[this.paixu2](Number(aa[i].amt_aim_final).toFixed(0))
        zhu2[this.paixu2](Number(aa[i].work_age_pov).toFixed(0))
      }
      this.y_zhou = y_zhou
      this.zhu1 = zhu1
      this.zhu2 = zhu2
      this.tupian9()
      if (this.quan_zia) {
        this.tupian9_da()
      }
    },
    // 点击销售额排序
    xiaoshou_paixu () {
      if (this.paixu3 == 'push') {
        this.paixu3 = 'unshift'
      } else if (this.paixu3 == 'unshift') {
        this.paixu3 = 'push'
      }
      const list = []
      const y_zhou = []
      const zhu1 = []
      const zhu2 = []
      const aa = this.$paixu(this.xinxi, 'amt_aim_final')
      for (let i = 0; i < aa.length; i++) {
        if (aa[i].work_age == 1) {
          aa[i].siling = '1年以内'
        }
        if (aa[i].work_age == 2) {
          aa[i].siling = '1-3年'
        }
        if (aa[i].work_age == 3) {
          aa[i].siling = '3-5年'
        }
        if (aa[i].work_age == 4) {
          aa[i].siling = '5-8年'
        }
        if (aa[i].work_age == 5) {
          aa[i].siling = '8-10年'
        }
        if (aa[i].work_age == 6) {
          aa[i].siling = '10年以上'
        }
        list[this.paixu3](aa[i])
        y_zhou[this.paixu3](aa[i].dept_name + '         ' + aa[i].staff_name + '         ' + aa[i].siling)
        zhu1[this.paixu3](Number(aa[i].amt_aim_final).toFixed(0))
        zhu2[this.paixu3](Number(aa[i].work_age_pov).toFixed(0))
      }
      this.y_zhou = y_zhou
      this.zhu1 = zhu1
      this.zhu2 = zhu2
      this.tupian9()
      if (this.quan_zia) {
        this.tupian9_da()
      }
    },
    tupian9 () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts9'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
          }
        },
        grid: {
          left: '6%',
          right: '4%',
          bottom: '3%',
          top: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: [
          {
            name: '目标销售额',
            type: 'bar',
            label: {
              show: true,
              position: 'insideLeft'
            },
            data: this.zhu1,
            itemStyle: {
              normal: { 
                color: function (params) { 
                  console.log(params)
                  if (params.name.indexOf('1年以内') != -1) {
                    return '#C55B0E'
                  }
                  if (params.name.indexOf('1-3年') != -1) {
                    return '#7F5F00'
                  }
                  if (params.name.indexOf('3-5年') != -1) {
                    return '#BE9002'
                  }
                  if (params.name.indexOf('5-8年') != -1) {
                    return '#FFBF04'
                  }
                  if (params.name.indexOf('8-10年') != -1) {
                    return '#ED7D32'
                  }
                  if (params.name.indexOf('10年以上') != -1) {
                    return '#ED7D32'
                  }
                } 
              } 
            } 
          },
          // {
          //     name: '目标销售额',
          //     type: 'line',
          //     label: {
          //         show: false,
          //     },
          //     data:this.zhu1,
          //     smooth: true,
          //     itemStyle: {
          //         normal: { 
          //             color: '#FFB466', 
          //         } 
          //     } 
          // },
          {
            name: '人均产值',
            type: 'line',
            label: {
              show: false
            },
            data: this.zhu2,
            itemStyle: {
              normal: { 
                color: '#F16977' 
              } 
            } 
          }
        ]
      })
      this.autoHeight = this.y_zhou.length * 60
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize() 
    },
    tupian9_da () {
      const echarts = require('echarts')
      const myChart = echarts.init(document.getElementById('myCharts9_da'))
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
          }
        },
        grid: {
          left: '6%',
          right: '4%',
          bottom: '3%',
          top: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#A0A4AA',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'category',
          data: this.y_zhou,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#BDC8D3'
            }
          },
          axisLabel: {
            color: '#4C4A4D',
            fontSize: 12
          }
        },
        series: [
          {
            name: '目标销售额',
            type: 'bar',
            label: {
              show: true,
              position: 'insideLeft'
            },
            data: this.zhu1,
            itemStyle: {
              normal: { 
                color: function (params) { 
                  console.log(params)
                  if (params.name.indexOf('1年以内') != -1) {
                    return '#C55B0E'
                  }
                  if (params.name.indexOf('1-3年') != -1) {
                    return '#7F5F00'
                  }
                  if (params.name.indexOf('3-5年') != -1) {
                    return '#BE9002'
                  }
                  if (params.name.indexOf('5-8年') != -1) {
                    return '#FFBF04'
                  }
                  if (params.name.indexOf('8-10年') != -1) {
                    return '#ED7D32'
                  }
                  if (params.name.indexOf('10年以上') != -1) {
                    return '#ED7D32'
                  }
                } 
              } 
            } 
          },
          // {
          //     name: '目标销售额',
          //     type: 'line',
          //     label: {
          //         show: false,
          //     },
          //     data:this.zhu1,
          //     smooth: true,
          //     itemStyle: {
          //         normal: { 
          //             color: '#FFB466', 
          //         } 
          //     } 
          // },
          {
            name: '人均产值',
            type: 'line',
            label: {
              show: false
            },
            data: this.zhu2,
            itemStyle: {
              normal: { 
                color: '#F16977' 
              } 
            } 
          }
        ]
      })
      this.autoHeight = this.y_zhou.length * 60
      myChart.getDom().style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].style.height = this.autoHeight + 'px'

      myChart.getDom().childNodes[0].childNodes[0].setAttribute('height', this.autoHeight)

      myChart.getDom().childNodes[0].childNodes[0].style.height = this.autoHeight + 'px'

      myChart.resize() 
    },
    // 点击关闭
    dianji_guanbi () {
      // this.$router.push('/aspreackeven')
      // this.quan_zia=''
      // this.$emit('nianfen_fu', '1')
      // sessionStorage.setItem("pc_mlbb_mubiao_biao", '-1');
      if (this.biaoge_nei) {
        this.quan_zia = false
        this.you_xiaoping = false
      } else {
        this.quan_zia = false
      }
      // this.yierji_panduan2_da=true
    },
    biaoge_dakai () {
      this.you_xiaoping = false
    },
    dakai_quanping (data) {
      console.log(data)
      this.you_xiaoping = true
      this.time = data.time
      this.quan_zia = data.chutu
      this.biaoge_nei = data.biaoge_nei
      this.chaxun_jiekou()
      // if(data.chutu){
      // this.nianfen_zhi()
      // this.nianfen_zhi2()
      // }else{
      //     // this.nianfen_zhi2()
      // }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.figurefive {
    height:100%;
    width:100%;
    // position: relative;
    .xiaoping{
        height:100%;
        width:100%;
        .xuanze_nianfen{
            padding-top:0.18rem;
            margin-left: 0.16rem;
            margin-right: 0.16rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                &:nth-child(1){
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
                &:nth-child(2){
                    font-size: 0.12rem;
                    color:#1A2533;
                    display: flex;
                    align-items: center;
                    span{
                        height:0.18rem;
                        width:0.18rem;
                        border-radius: 50%;
                        display: inline-block;
                        &:nth-child(1){
                            background:#6ABFA7;
                        }
                        &:nth-child(2){
                            background:#F96C6C;
                        }
                    }
                }
                &:nth-child(3){
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
            }
        }
        .table_con{
            width:95%;
            margin: 0 auto;
            margin-top: 0.11rem;
        }
        .zhanshi_liebiao_2{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0.1rem 0;
            li{
                display: flex;
                align-items: center;
                font-size: 0.1rem;
                color:#4C4A4D;
                margin-right: 0.16rem;
                &:nth-child(1){
                    span{
                        display: inline-block;
                        height:0.08rem;
                        width:0.08rem;
                        background:#F16977;
                        margin-right: 0.03rem;
                    }
                }
                // &:nth-child(2){
                //     span{
                //         display: inline-block;
                //         height:0.08rem;
                //         width:0.08rem;
                //         background:#FFB466;
                //         margin-right: 0.03rem;
                //     }
                // }
                &:nth-child(2),&:nth-child(3),&:nth-child(4){
                    cursor: pointer;
                    img{
                        height:0.18rem;
                        width:0.18rem;
                        margin-left: 0.08rem;
                    }
                }
            }
        }
        .zhishi_liebiao{
            display: flex;
            align-items: center;
            margin-left:0.48rem;
            li{
                font-size: 0.12rem;
                color:#4C4A4D;
                margin: 0 0.2rem;
            }
        }
        .zhanshi_huabu{
            height:3rem;
            overflow-y:auto;
            &::-webkit-scrollbar {
                width: 0.2rem;
                height: 0.08rem;
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
        }
    }
    .quanping{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .box{
            position: relative;
            height:90%;
            width: 14rem;
            left:50%;
            top:5%;
            margin-left:-8rem;
            background: #F1F3F9;
            .box_text{
                position: absolute;
                font-size: 0.17rem;
                color:#4C4A4D;
                left:5%;
                top:3%;
            }
            .guanbi{
                position: absolute;
                top:-0.2rem;
                right:-0.2rem;
                height:0.6rem;
                width: 0.6rem;
                border-radius: 50%;
                background: #fc6a6a;
                color:#fff;
                text-align: center;
                font-size: 0.3rem;
                line-height: 0.6rem;
                box-shadow:0 0 0.05rem 0.05rem rgba(27, 27, 27, 0.5);
                cursor: pointer;
            }
            .box_con{
                position: relative;
                height:86%;
                width: 90%;
                left:5%;
                top:7%;
                background: #fff;
                .xuanze_nianfen{
                    padding-top:0.18rem;
                    margin:0 2.5%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        &:nth-child(1){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                        &:nth-child(2){
                            font-size: 0.12rem;
                            color:#1A2533;
                            display: flex;
                            align-items: center;
                            span{
                                height:0.18rem;
                                width:0.18rem;
                                border-radius: 50%;
                                display: inline-block;
                                &:nth-child(1){
                                    background:#6ABFA7;
                                }
                                &:nth-child(2){
                                    background:#F96C6C;
                                }
                            }
                        }
                        &:nth-child(3){
                            font-size: 0.12rem;
                            color:#4C4A4D;
                        }
                    }
                }
                .table_con{
                    width:95%;
                    height:90%;
                    margin: 0 auto;
                    margin-top: 0.11rem;
                    position: relative;
                    .zhanshi_liebiao_2{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin: 0.1rem 0;
                        li{
                            display: flex;
                            align-items: center;
                            font-size: 0.1rem;
                            color:#4C4A4D;
                            margin-right: 0.16rem;
                            &:nth-child(1){
                                span{
                                    display: inline-block;
                                    height:0.08rem;
                                    width:0.08rem;
                                    background:#F16977;
                                    margin-right: 0.03rem;
                                }
                            }
                            // &:nth-child(2){
                            //     span{
                            //         display: inline-block;
                            //         height:0.08rem;
                            //         width:0.08rem;
                            //         background:#FFB466;
                            //         margin-right: 0.03rem;
                            //     }
                            // }
                            &:nth-child(2),&:nth-child(3),&:nth-child(4){
                                cursor: pointer;
                                img{
                                    height:0.18rem;
                                    width:0.18rem;
                                    margin-left: 0.08rem;
                                }
                            }
                        }
                    }
                    .zhishi_liebiao{
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top:0.2rem;
                        left:0.85rem;
                        z-index: 2;
                        li{
                            font-size: 0.12rem;
                            color:#4C4A4D;
                            margin: 0 0.23rem;
                        }
                    }
                    .zhanshi_huabu2{
                        height:6rem;
                        overflow-y:auto;
                        &::-webkit-scrollbar {
                            width: 0.2rem;
                            height: 0.08rem;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            border-radius: 0.12rem;
                            border: 0.06rem solid rgba(0, 0, 0, 0);
                            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                        }
                        
                        &::-webkit-scrollbar-thumb:hover {
                            box-shadow: 0.08rem 0 0 #4A4A4A inset;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .quanping {
            width:1600px;
        }
    }
}
</style>
