<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tablefive">
        <div class="beijing" v-if="nianfen_xianshi_yincang">
            <div class="box1">
                <p class="tit_box">人员销售目标</p>
                <p class="con">请选择创建人员销售目标的年份</p>
                <div class="con_box">
                    选择年份&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-date-picker
                        v-model="nian_sj"
                        type="year"
                        format='yyyy年'
                        placeholder="选择年">
                    </el-date-picker>
                </div>
                <div class="foot">
                    <span @click="xuanze_nianfen_queding">确定</span>
                    <span @click="xuanze_nianfen_quxiao">取消</span>
                </div>
            </div>
        </div>
        <div class="box">
            <p class="box_tit">{{time}}&nbsp;&nbsp;人员销售目标</p>
            <p class="lishi" @click="dianji_lishi_chaxun">历史查询<i></i></p>
            <div class="table_tit">
                <div class="table_tit_left">
                    <p class="xinjian" @click="nianfen_xianshi_yincang=true">新建</p>
                    <div class="table_tit_left_nianfen">
                        年份
                        <el-select v-model="time" @change='xuanze_nianfen_biao'>
                            <el-option
                                v-for="(i,index) in year_list"
                                :key="index"
                                :label="i"
                                :value="i">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="table_tit_right">
                    <!-- 筛选 -->
                    单位 : 元
                    <!-- <el-switch
                        class="demo"
                        v-model='danwei'
                        @change="danwei_xuanze"
                        active-color="#ff4949"
                        inactive-color="#ff4949"
                        active-text="万元"
                        inactive-text="元">
                    </el-switch> -->
                </div>
            </div>
            <div class="table">
                <div class="table_left">
                    <p @click="dianji_tiaozheng">调整目标</p>
                </div>
                <div class="table_right">
                    <p @click="dianji_guanbi">关闭</p>
                    <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>   <!--shifou_baocun  true不能点false能点-->
                    <!-- :style="shifou_baocun?'background:#B8C1CE;border:0.01rem solid #B8C1CE;':''" -->
                    <p @click="dianji_chutu" :style="chutu?'background:#9a86db;border:0.01rem solid #9a86db;color:#fff;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;color:#fff;'">出图</p>
                    <!-- :style="zhanshi==1?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'"  -->
                </div>
            </div>
            <div class="table_con">
                <el-table :data="neirong_list"
                    border
                    height="5rem"
                    :cell-style="cellStyle_tiao2"
                    :header-cell-style="headerCellStyle"
                    :span-method="arraySpanMethod"
                    >
                    <el-table-column
                        prop="dept_name"
                        label="部门">
                    </el-table-column>
                    <el-table-column
                        prop="amt_aim_final"
                        label="本年目标销售额(总金额)">
                    </el-table-column>
                    <el-table-column
                        prop="fenpei"
                        label="本年目标销售额(已分配)">
                    </el-table-column>
                    <el-table-column
                        prop="wei_fenpei"
                        label="本年目标销售额(未分配)">
                    </el-table-column>
                    <el-table-column
                        prop="staff_name"
                        label="人员">
                    </el-table-column>
                    <el-table-column
                        prop="ren_aim"
                        label="本年目标销售额">
                        <template slot-scope="props">
                            <input v-if="chaxun_or_xiugai" type="number" v-model="props.row.ren_aim">
                            <span v-if="!chaxun_or_xiugai">{{props.row.ren_aim}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <table class="laoye-tbody" border="1">
                    <tr class="laoye-tit">
                        <td>合计</td>
                        <td style="text-align:right;padding-right:0.14rem;">{{zong_jine}}</td>
                        <td style="text-align:right;padding-right:0.14rem;">{{yifen_pei}}</td>
                        <td style="text-align:right;padding-right:0.14rem;">{{wei_fenpei}}</td>
                        <td></td>
                        <td style="text-align:right;padding-right:0.14rem;">{{mubiao_e}}</td>
                    </tr>
                </table>
            </div>
            <div class="tiaozheng_mubiao" v-if="tiaozheng_list" @click="tiaozheng_list=false">
                <div class="tiaozheng_box" @click.stop>
                    <div class="tiaozheng_con">
                        <p class="tiaozheng_tit">调整目标</p>
                        <div class="tiaozheng_tit_right">
                            <!-- <p>筛选</p> -->
                            <p>单位 :元</p>
                        </div>
                        <div class="table">
                            <p @click="dianji_tianjia_renyuan">添加人员</p>
                            <div>
                                <p :style="tiaozheng_shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'" @click="tiaozheng_baocun">保存</p>
                                <p @click="tiaozheng_list=false" style="margin-right:0;">关闭</p>
                            </div>
                        </div>
                        <div class="table_con">
                            <el-table :data="tiaozheng_neirong"
                                border
                                :span-method="arraySpanMethod2"
                                :cell-style="cellStyle_tiao"
                                height='6rem'
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column v-for="(i, ind) in tiaozheng_biaotou"
                                    :key="`col_${ind}`"
                                    :prop="tiaozheng_biaotou[ind].con"
                                    :label="i.name">
                                    <template slot-scope="props">
                                        {{tiaozheng_biaotou[ind].con!='tiaozheng'?props.row[tiaozheng_biaotou[ind].con]:''}}
                                        <input type="number" v-if="tiaozheng_biaotou[ind].con=='tiaozheng'" v-model="props.row[tiaozheng_biaotou[ind].con]">
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tiaozheng_mubiao" v-if="lishi_chaxun_list" @click="lishi_chaxun_list=false">
                <div class="tiaozheng_box" @click.stop>
                    <div class="tiaozheng_con">
                        <p class="tiaozheng_tit">历史查询目标调整</p>
                        <div class="tiaozheng_tit_right">
                            <!-- <p>筛选</p> -->
                            <p>单位 : 元</p>
                        </div>
                        <div class="table">
                            <span></span>
                            <!-- <p style="display:none"></p> -->
                            <p @click="lishi_chaxun_list=false" style="margin-right:0;">关闭</p>
                        </div>
                        <div class="table_con">
                            <el-table :data="lishi_neirong"
                                border
                                max-height="600"
                                :span-method="arraySpanMethod3"
                                :cell-style="cellStyle_tiao"
                                :header-cell-style="headerCellStyle"
                                >
                                <el-table-column v-for="(i, ind) in lishi_tit"
                                    :key="`col_${ind}`"
                                    :prop="lishi_tit[ind].con"
                                    :label="i.name">
                                    <template slot-scope="props">
                                        {{props.row[lishi_tit[ind].con]=='-123456789.0000'||props.row[lishi_tit[ind].con]=='null'?'':props.row[lishi_tit[ind].con]}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="lianxiren">
            <div class="tiao_bumen">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <!-- <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div> -->
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                        <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
                        <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.label}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" @click="dianji_xuanren(i)" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                    <img class="img2" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.name}}--{{i.dept_name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="renyuan_sure">确定</p>
                <p class="quxiao" @click="lianxiren=false">取消</p>
            </div>
        </el-dialog>
        <tuwu ref="mubiao_tu5"></tuwu>
    </div>
</template>

<script>
import tuwu from '../Figures/Figurefive'
import { query_ent_dept_list_aim_tile, query_dept_staff_list, query_ent_dept_list, query_as_staff_aim_log, update_as_staff_aim, insert_as_staff_aim, supply_as_staff_aim_data, query_as_staff_aim, query_as_year_list } from '../../api/api.js'
export default {
  name: 'tablefive',
  data () {
    return {
      time: '',
      nian_sj: '',
      year_list: [],
      shifou_baocun: false,
      neirong_list: [],
      spanArr: [],
      pos: 0,
      spanArr2: [],
      pos2: 0,
      spanArr3: [],
      pos3: 0,
      nianfen_xianshi_yincang: '',
      indexArr: [],
      indexArr2: [],
      indexArr3: [],
      chaxun_or_xiugai: '',
      tiaozheng_neirong: [],
      tiaozheng_list: '',
      tiaozheng_shifou_baocun: '',
      tiaozheng_biaotou: [
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '人员',
          con: 'staff_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        },
        {
          name: '本年目标销售额调整',
          con: 'tiaozheng'
        },
        {
          name: '调整后本年目标销售额',
          con: 'tiaozheng_hou'
        }
      ],
      lishi_chaxun_list: false,
      lishi_tit: [],
      lishi_neirong: [],
      chutu: false,
      lianxiren: false,
      text: '',
      mianbao_list: [],
      qiye_renyuan_list: [],
      qiye_bumen_list: [],
      qiye_renyuan_list_xuan: [],
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list_beiyong: [], // 备用
      zong_jine: '0',
      yifen_pei: '0',
      wei_fenpei: '0',
      mubiao_e: '0'
    }
  },
  created () {
    this.jichu()
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  components: { tuwu },
  mounted () {
    this.$refs.mubiao_tu5.biaoge_dakai()
  },
  watch: {
    // 监听调整里的值
    neirong_list: {
      handler (newValue, oldValue) {
        this.zidong_yi_fenpei()
        this.panduan()
        this.zidong_heji()
      },
      deep: true
    },
    tiaozheng_neirong: {
      handler (newValue, oldValue) {
        this.zidong_tiaozheng_hou()
        this.chaxun_tiaozheng_baocun()
      },
      deep: true
    },
    lianxiren () {
      if (!this.lianxiren) {
        this.qiye_renyuan_list_xuan = []
        this.qiye_renyuan_list = []
      }
    }
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '5'
        }
      }).then(res => {

        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)
          //console.log(date)
          if (date.length != 0) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
            date.forEach(it => {
              if (it == new Date().getFullYear()) {
                this.time = new Date().getFullYear() + '年'
                this.xuanze_nianfen_biao()
              }
            })
            this.nianfen_xianshi_yincang = false
          } else {
            this.nianfen_xianshi_yincang = true
          }
        } else if (res.data.code == 10238) {}
      })
    },
    // 选择年份
    xuanze_nianfen_biao () {
      //console.log(this.time)
      this.chaxun_jiekou()
    },
    chaxun_jiekou () {
      query_as_staff_aim({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {

        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date,'这是要返回的数据')
          const list = []
          this.spanArr = []
          this.pos = 0
          for (let i = 0; i < date.length; i++) {
            for (let a = 0; a < date[i].staff_list.length; a++) {
              list.push({
                dept_name: date[i].dept_name,
                dept_id: date[i].dept_id,
                amt_aim_final: this.$qianwei(Number(date[i].amt_aim_final).toFixed(0)),
                fenpei: '',
                wei_fenpei: '',
                staff_name: date[i].staff_list[a].active == 0 ? date[i].staff_list[a].staff_name + ' (已离职)' : date[i].staff_list[a].staff_name,
                staff_id: date[i].staff_list[a].staff_id,
                ren_aim: this.$qianwei(Number(date[i].staff_list[a].amt_aim_final).toFixed(0))
              })
            }
          }
          this.chaxun_or_xiugai = false
          this.neirong_list = list
          this.chaxun_shifou_chutu()
          this.getSpanArr(this.neirong_list)
        } else if (res.data.code == 500) {}
      })
    },
    getSpanArr (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].dept_name === data[i - 1].dept_name) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    // 处理行的合并
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 4
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getSpanArr2 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr2.push(1)
          this.pos2 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].dept_name === data[i - 1].dept_name) {
            this.spanArr2[this.pos2] += 1
            this.spanArr2.push(0)
          } else {
            this.spanArr2.push(1)
            this.pos2 = i
          }
        }
      }
    },
    // 处理行的合并
    arraySpanMethod2 ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr2.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr2[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getSpanArr3 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr3.push(1)
          this.pos3 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].dept_name === data[i - 1].dept_name) {
            this.spanArr3[this.pos3] += 1
            this.spanArr3.push(0)
          } else {
            this.spanArr3.push(1)
            this.pos3 = i
          }
        }
      }
    },
    // 处理行的合并
    arraySpanMethod3 ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr3.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr3[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 自动计算
    zidong_yi_fenpei () {
      const liebiao = []
      for (let i = 0; i < this.neirong_list.length; i++) {
        if (this.neirong_list[i].ren_aim != '') {
          liebiao.push(this.neirong_list[i].dept_id)
        }
      }
      const zz = this.$quchong(liebiao)
      //console.log(zz)
      if (zz.length != 0) {
        for (let i = 0; i < zz.length; i++) {
          let zhi = ''
          for (let a = 0; a < this.neirong_list.length; a++) {
            if (this.neirong_list[a].dept_id == zz[i]) {
              zhi = this.$shuzi(this.neirong_list[a].ren_aim) + Number(zhi)
            }
          }
          for (let a = 0; a < this.neirong_list.length; a++) {
            if (this.neirong_list[a].dept_id == zz[i]) {
              this.neirong_list[a].fenpei = this.$qianwei(zhi.toFixed(0))
              this.neirong_list[a].wei_fenpei = this.$qianwei((this.$shuzi(this.neirong_list[a].amt_aim_final) - Number(zhi)).toFixed(0))
            }
          }
          //console.log(zhi)
        }
      }
    },
    zidong_tiaozheng_hou () {
      for (let i = 0; i < this.tiaozheng_neirong.length; i++) {
        if (this.tiaozheng_neirong[i].tiaozheng != '') {
          this.tiaozheng_neirong[i].tiaozheng_hou = this.$qianwei((this.$shuzi(this.tiaozheng_neirong[i].amt_aim) + Number(this.tiaozheng_neirong[i].tiaozheng)).toFixed(0))
        } else {
          this.tiaozheng_neirong[i].tiaozheng_hou = this.tiaozheng_neirong[i].amt_aim
        }
      }
    },
    // 判断是否可以点保存
    panduan () {
      if (this.year_list.length != 0) {
        const a = []
        for (let i = 0; i < this.year_list.length; i++) {
          if (this.time != this.year_list[i]) {
            a.push(1)
          }
        }
        if (a.length == this.year_list.length) {
          this.panduan_shifou()
        } else {
          this.shifou_baocun = false
          //console.log(222)
        }
      } else {
        this.panduan_shifou()
      }
    },
    panduan_shifou () {
      const a = []
      for (let i = 0; i < this.neirong_list.length; i++) {
        if (this.neirong_list[i].ren_aim != '') {
          a.push(1)
        }
      }
      if (a.length == this.neirong_list.length) {
        this.shifou_baocun = true
      } else {
        this.shifou_baocun = false
      }
      //console.log(111)
    },
    // 点击保存
    dianji_baocun () {
      if (this.shifou_baocun) {
        const list = []
        for (let i = 0; i < this.neirong_list.length; i++) {
          list.push({
            dept_id: this.neirong_list[i].dept_id,
            staff_id: this.neirong_list[i].staff_id,
            amt_aim: this.neirong_list[i].ren_aim
          })
        }
        insert_as_staff_aim({
          data: {
            user_id: this.$jichuxinxi().user_id,
            ent_id: this.$ent_id(),
            year: this.time.slice(0, 4),
            list: list
          }
        }).then(res => {
          //console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.shifou_baocun = false
            this.chutu = true
            this.chaxun_or_xiugai = false
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '已保存或未填写完整信息',
          type: 'warning'
        })
      }
    },
    // 点击出图
    dianji_chutu () {
      if (this.chutu) {
        // this.chuxian='111'
        // this.quan=this.time
        // //console.log(this.chuanshu)

        this.$refs.mubiao_tu5.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '请先进行保存',
          type: 'warning'
        })
      }
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;width:2.5rem;'
    },
    cellStyle_tiao (row) {
      //console.log(row)
      // if(row.col.zhi!=undefined){
      if (row.column.label.indexOf('本年目标销售调整') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.column.label.indexOf('调整后本年目标销售额') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;background:#E2EFDA;width:2.5rem;'
      }
      if (row.column.label.indexOf('本年目标销售额') != -1) {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
      // }
    },
    // 内容样式
    cellStyle_tiao2 (row) {
      // if(row.col.zhi!=undefined){
      if (row.column.label == '本年目标销售额(总金额)' || row.column.label == '本年目标销售额(已分配)' || row.column.label == '本年目标销售额(未分配)') {
        return 'color:#4C4A4D;background:#E2EFDA;font-size:0.12rem;text-align:right;width:2.5rem;'
      }
      if (row.column.label == '本年目标销售额') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
      // }
    },
    // 选择年份确定
    xuanze_nianfen_queding () {
      if (this.nian_sj != '') {
        let yiyou_nianfen = false
        this.year_list.forEach(item => {
          if (this.nian_sj.getFullYear() + '年' == item) {
            yiyou_nianfen = true
            this.nian_sj = ''
          }
        })
        if (!yiyou_nianfen) {
          supply_as_staff_aim_data({
            data: {
              ent_id: this.$ent_id(),
              year: this.nian_sj.getFullYear() + ''
            }
          }).then(res => {
            //console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              //console.log(date,'这是打印出来的数据')
              if (date.length == 0) {
                this.$message({
                  message: '没有该年份的人员销售目标',
                  type: 'warning'
                })
              } else {
                const list = []
                this.spanArr = []
                this.pos = 0
                for (let i = 0; i < date.length; i++) {
                  for (let a = 0; a < date[i].staff_list.length; a++) {
                    list.push({
                      dept_name: date[i].dept_name,
                      dept_id: date[i].dept_id,
                      amt_aim_final: this.$qianwei(Number(date[i].amt_aim_final).toFixed(0)),
                      fenpei: '0',
                      wei_fenpei: '0',
                      staff_name: date[i].staff_list[a].staff_name,
                      staff_id: date[i].staff_list[a].staff_id,
                      ren_aim: ''
                    })
                  }
                }

                this.neirong_list = list
                this.nianfen_xianshi_yincang = false
                this.getSpanArr(this.neirong_list)
                this.chaxun_or_xiugai = true
                this.time = this.nian_sj.getFullYear() + '年'
              }
            } else if (res.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '已有该年份数据',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请选择年份',
          type: 'warning'
        })
      }
    },
    // 选择年份取消
    xuanze_nianfen_quxiao () {
      if (this.year_list.length == 0) {
        this.$router.push('/aspreackeven')
        sessionStorage.setItem('pc_mlbb_mubiao_biao', '-1')
      } else {
        this.nianfen_xianshi_yincang = false
      }
    },
    // 点击调整目标
    dianji_tiaozheng () {
      if (!this.chaxun_or_xiugai && this.time != '') {
        this.tiaozheng_list = true
        this.tiaozheng_neirong = []
        for (let i = 0; i < this.neirong_list.length; i++) {
          this.tiaozheng_neirong.push({
            dept_id: this.neirong_list[i].dept_id,
            dept_name: this.neirong_list[i].dept_name,
            staff_id: this.neirong_list[i].staff_id,
            staff_name: this.neirong_list[i].staff_name,
            amt_aim: this.neirong_list[i].ren_aim,
            tiaozheng: '',
            tiaozheng_hou: ''
          })
        }
        this.spanArr2 = []
        this.pos2 = 0
        this.indexArr2 = []
        this.getSpanArr2(this.tiaozheng_neirong)
      } else {
        this.$message({
          message: '该表格还未进行保存',
          type: 'warning'
        })
      }
    },
    chaxun_tiaozheng_baocun () {
      const a = []
      for (let i = 0; i < this.tiaozheng_neirong.length; i++) {
        if (this.tiaozheng_neirong[i].tiaozheng == '') {
          a.push(1)
        }
      }
      if (a.length == this.tiaozheng_neirong.length) {
        this.tiaozheng_shifou_baocun = false
      } else {
        this.tiaozheng_shifou_baocun = true
      }
    },
    // 调整保存
    tiaozheng_baocun () {
      if (this.tiaozheng_shifou_baocun) {
        const list = []
        const cuowu = []
        this.tiaozheng_neirong.forEach(item => {
          if (item.tiaozheng.length != 0 && item.tiaozheng != 0) {
            if (this.$shuzi(item.tiaozheng_hou) < 0) {
              cuowu.push('1111')
            }
          }
        })
        if (cuowu.length == 0) {
          for (let i = 0; i < this.tiaozheng_neirong.length; i++) {
            if (this.tiaozheng_neirong[i].tiaozheng != '') {
              list.push({
                dept_id: this.tiaozheng_neirong[i].dept_id,
                staff_id: this.tiaozheng_neirong[i].staff_id,
                amt_adjust: this.$shuzi(this.tiaozheng_neirong[i].tiaozheng) + '',
                amt_aim: this.$shuzi(this.tiaozheng_neirong[i].tiaozheng_hou) + ''
              })
            }
          }
          update_as_staff_aim({
            data: {
              user_id: this.$jichuxinxi().user_id,
              ent_id: this.$ent_id(),
              year: this.time.slice(0, 4),
              list: list
            }
          }).then(res => {
            //console.log(res)
            if (res.data.code == 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.tiaozheng_list = false
              this.spanArr = []
              this.pos = 0
              this.chaxun_jiekou()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.$message({
            message: '目标不能为负值',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '请填写完整的信息',
          type: 'warning'
        })
      }
    },
    // 打开历史查询
    dianji_lishi_chaxun () {
      if (this.time != '') {
        this.lishi_chaxun_list = true
        query_as_staff_aim_log({
          data: {
            ent_id: this.$ent_id(),
            year: this.time.slice(0, 4)
          }
        }).then(res => {
          //console.log(res)
          if (res.data.code == 200) {
            this.spanArr3 = []
            this.pos3 = 0
            const date = JSON.parse(res.data.body.data)
            const data = JSON.parse(res.data.body.date_list)
            //console.log(date)
            //console.log(data)
            const list1 = [
              {
                name: '部门',
                con: 'dept_name'
              },
              {
                name: '人员',
                con: 'staff_name'
              },
              {
                name: '本年目标销售额',
                con: 'amt_aim'
              }
            ]
            for (let i = 0; i < data.length; i++) {
              list1.push({
                name: '本年目标销售调整(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
                con: '调整金额' + data[i]
              })
              list1.push({
                name: '调整后本年目标销售额(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
                con: data[i]
              })
            }
            const list2 = []
            this.lishi_tit = list1
            for (let i = 0; i < date.length; i++) {
              if (date[i].log_list.length != 0) {
                for (let a = 0; a < date[i].log_list.length; a++) {
                  list2.push(date[i].log_list[a])
                }
              }
            }
            this.lishi_neirong = list2
            this.getSpanArr3(this.lishi_neirong)
            // this.lishi_list=date
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '请您选择年份在查询',
          type: 'warning'
        })
      }
    },
    // 查询是否可以点击出图
    chaxun_shifou_chutu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '5'
        }
      }).then(res => {
        //console.log(res)
        if (res.data.code == 10237) {
          const data = JSON.parse(res.data.body.data)
          //console.log(data)
          //console.log(this.time)
          if (data.length != 0) {
            // if(data.map(item=>{if(this.time.slice(0,4)==item){return true}})){
            //     this.chutu=true
            // }
            for (let a = 0; a < data.length; a++) {
              if (data[a] == this.time.slice(0, 4)) {
                this.chutu = true
                break
              }
            }
          } else {
            this.chutu = false
          }
        }
      })
    },
    dianji_tianjia_renyuan () {
      this.dakai_bumen()
      this.lianxiren = true
    },
    // 部门列表   自己的
    bumen_liebiao () {
      query_ent_dept_list_aim_tile({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        //console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_bumen_list = date
          this.qiye_bumen_list_beiyong = date
        } else if (res.data.code == 10169) {}
      })
      // query_ent_dept_list({data:{
      //     ent_id:this.$ent_id(),
      //     active:'1',
      //     parent_id:this.mianbao_list[this.mianbao_list.length-1].dept_id
      // }}).then(res=>{
      //     //console.log(res)
      //     if(res.data.code==10168){
      //         let date=JSON.parse(res.data.body.data)
      //         //console.log(date)
      //         this.qiye_bumen_list=date
      //     }else if(res.data.code==10169){
      //     }
      // })
      // query_dept_staff_list({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id,
      //     dept_id:this.mianbao_list[this.mianbao_list.length-1].dept_id,
      //     active:'1'
      // }}).then(ras=>{
      //     if(ras.data.code==10149){
      //         let listdata=JSON.parse(ras.data.body.data)
      //         listdata.map(item=>{item['zhi']=false})
      //         //console.log(99999,listdata)
      //         this.qiye_renyuan_list=listdata
      //         this.panduan_yixuan()
      //     }else if(ras.data.code==10150){
      //     }
      // })
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
    },
    // 单选人
    // dianji_xuanren(index){
    //     this.renyuan=index
    // },
    // 点击部门
    dianji_bumen (i) {
      //console.log(44444, i)
      // if(i.child_flag==1){
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          //console.log(date)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.label, dept_id: i.dept_id })
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              dept_id: i.dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              //console.log(this.tiaozheng_neirong)
              const list2 = []
              this.tiaozheng_neirong.forEach(item => {
                if (item.dept_id == i.dept_id) {
                  list2.push(item)
                }
              })
              //console.log(list2)
              setTimeout(() => {
                for (let item = 0; item < listdata.length; item++) {
                  //console.log(listdata[item].staff_id)
                  list2.forEach(ite => {
                    //console.log(ite)
                    if (ite.staff_id == listdata[item].staff_id) {
                      this.$set(listdata[item], 'teshu', true)
                    }
                  })
                }
                setTimeout(() => {
                  const list = []
                  listdata.forEach(item => {
                    if (item.teshu == undefined) {
                      list.push(item)
                      item.zhi = false
                    }
                  })
                  //console.log(99999, list)
                  this.qiye_renyuan_list = list
                  this.panduan_yixuan()
                }, 0)
              }, 0)
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
      // }else{
      //     this.$message({
      //         message: '该部门没有子部门了',
      //         type: 'warning'
      //     });
      // }
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      //console.log(i.dept_id)
      if (index == 0) {
        this.qiye_bumen_list = this.qiye_bumen_list_beiyong
        this.mianbao_list.splice(index + 1, this.mianbao_list.length)
        this.qiye_renyuan_list = []
      } else {
        if (this.mianbao_list.length - 1 != index) {
          query_ent_dept_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              parent_id: i.dept_id,
              active: '1'
            }
          }).then(res => {
            if (res.data.code == 10168) {
              var date = JSON.parse(res.data.body.data)
              // //console.log(date)
              this.qiye_bumen_list = date
              this.mianbao_list.splice(index + 1, this.mianbao_list.length)
              // query_dept_staff_list({data:{
              //     ent_id:this.$ent_id(),
              //     user_id:this.$jichuxinxi().user_id,
              //     dept_id:i.dept_id,
              //     active:'1'
              // }}).then(ras=>{
              //     //console.log(ras)
              //     if(ras.data.code==10149){
              //         let listdata=JSON.parse(ras.data.body.data)
              //         listdata.map(item=>{item['zhi']=false})
              //         //console.log(99999,listdata)
              //         this.qiye_renyuan_list=listdata
              //         this.panduan_yixuan()
              //     }else if(ras.data.code==10150){
              //     }
              // })
            } else if (res.data.code == 10169) {
            }
          })
        }
      }
    },
    // 发起人里成员点击确定
    renyuan_sure () {
      //console.log(this.qiye_renyuan_list_xuan)
      const list = []
      this.qiye_renyuan_list_xuan.forEach(item => {
        if (item.zhi) {
          list.push(item)
        }
      })
      setTimeout(() => {
        if (list.length != 0) {
          for (let i = 0; i < list.length; i++) {
            const zhi = []
            for (let a = 0; a < this.tiaozheng_neirong.length; a++) {
              if (list[i].dept_id == this.tiaozheng_neirong[a].dept_id) {
                this.tiaozheng_neirong.splice(a, 0, {
                  dept_id: list[i].dept_id,
                  dept_name: list[i].dept_name,
                  staff_id: list[i].staff_id,
                  staff_name: list[i].staff_name,
                  amt_aim: 0,
                  tiaozheng: '',
                  tiaozheng_hou: ''
                })
                zhi.push('111')
                break
              }
            }
            if (zhi.length == 0) {
              this.tiaozheng_neirong.push({
                dept_id: list[i].dept_id,
                dept_name: list[i].dept_name,
                staff_id: list[i].staff_id,
                staff_name: list[i].staff_name,
                amt_aim: 0,
                tiaozheng: '',
                tiaozheng_hou: ''
              })
            }
          }
          this.spanArr2 = []
          this.pos2 = 0
          this.indexArr2 = []
          this.getSpanArr2(this.tiaozheng_neirong)
          //console.log(this.tiaozheng_neirong)
        }
        this.lianxiren = false
      }, 0)
    },
    // 点击全选
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          if (this.qiye_renyuan_list_xuan[i].dept_id == this.mianbao_list[this.mianbao_list.length - 1].dept_id) {
            this.qiye_renyuan_list_xuan.splice(i, 1)
            i = i - 1
          }
        }
        setTimeout(() => {
          this.qiye_renyuan_list.forEach(item => {
            item.zhi = true
            this.$set(item, 'dept_id', this.mianbao_list[this.mianbao_list.length - 1].dept_id)
            this.$set(item, 'dept_name', this.mianbao_list[this.mianbao_list.length - 1].name)
            this.qiye_renyuan_list_xuan.push(item)
          })
        }, 0)
        // this.qiye_renyuan_list_xuan=this.$func.Es5duplicate(this.qiye_renyuan_list_xuan,'staff_id')
      } else {
        this.qiye_renyuan_list.forEach(item => { item.zhi = false })
        // for(let i=0;i<this.qiye_renyuan_list.length;i++){
        //     for(let a=0;a<this.qiye_renyuan_list_xuan.length;a++){
        //         if(this.qiye_renyuan_list[i].staff_id==this.qiye_renyuan_list_xuan[a].staff_id){
        //             this.qiye_renyuan_list_xuan.splice(a,1)
        //         }
        //     }
        // }
        for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
          if (this.qiye_renyuan_list_xuan[i].dept_id == this.mianbao_list[this.mianbao_list.length - 1].dept_id) {
            this.qiye_renyuan_list_xuan.splice(i, 1)
            i = i - 1
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id && this.mianbao_list[this.mianbao_list.length - 1].dept_id == this.qiye_renyuan_list_xuan[a].dept_id) {
            this.qiye_renyuan_list[i].zhi = true
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'dept_id', this.mianbao_list[this.mianbao_list.length - 1].dept_id)
        this.$set(i, 'dept_name', this.mianbao_list[this.mianbao_list.length - 1].name)
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id && i.dept_id == this.qiye_renyuan_list_xuan[a].dept_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    panduan_shifou_quanxuan () {
      const list = []
      //console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    zidong_heji () {
      const date = this.$func.Es5duplicate(this.neirong_list, 'dept_id')
      //console.log(date)
      let zong_jine = ''
      let yifen_pei = ''
      let wei_fenpei = ''
      let mubiao_e = ''
      date.forEach(item => {
        zong_jine = Number(zong_jine) + this.$shuzi(item.amt_aim_final)
        yifen_pei = Number(yifen_pei) + this.$shuzi(item.fenpei),
        wei_fenpei = Number(wei_fenpei) + this.$shuzi(item.wei_fenpei)
        mubiao_e = Number(mubiao_e) + this.$shuzi(item.fenpei)
      })
      this.zong_jine = this.$qianwei(Number(zong_jine).toFixed(0))
      this.yifen_pei = this.$qianwei(Number(yifen_pei).toFixed(0))
      this.wei_fenpei = this.$qianwei(Number(wei_fenpei).toFixed(0))
      this.mubiao_e = this.$qianwei(Number(mubiao_e).toFixed(0))
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tablefive {
    min-height:7.2rem;
    background:#F4F7FD;
    padding: 0.31rem 0.31rem 0.34rem 0.31rem;
    // width:100%;
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box1{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #9a86db;
                        border:0.01rem solid #9a86db;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
    .box{
        min-height:7.2rem;
        width:17.2rem;
        position: relative;
        .box_tit{
            font-size: 0.22rem;
            color:#1A2533;
            text-align: center;
            margin-bottom: 0.33rem;
        }
        .lishi{
            display: flex;
            align-items: center;
            position: absolute;
            right:0;
            top:0.04rem;
            font-size: 0.14rem;
            color:#4C4A4D;
            cursor: pointer;
            i{
                display: block;
                height:0.25rem;
                width:0.28rem;
                background: url('../../assets/rzhi_no.png') no-repeat;
                background-position:center center;
                margin-left: 0.1rem;
            }
            &:hover i{
                background: url('../../assets/rzhi_ok.png') no-repeat;
                background-position:center center;
            }
        }
        .table_tit{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.54rem;
            .table_tit_left{
                display: flex;
                align-items: center;
                .xinjian{
                    font-size: 0.14rem;
                    background:#9a86db;
                    padding: 0.03rem 0.16rem;
                    border:0.01rem solid #9a86db;
                    border-radius: 0.02rem;
                    color:#fff;
                    margin-right: 0.15rem;
                    cursor: pointer;
                }
                .table_tit_left_nianfen{
                    font-size: 0.14rem;
                    color:#4c4a4d;
                }
            }
            .table_tit_right{
                font-size: 0.1rem;
                color:#444;
            }
        }
        .table{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height:0.69rem;
            padding: 0 0.21rem;
            border:0.01rem solid #888;
            margin-top:0.2rem;
            div{
                display: flex;
                align-items: center;
                p{
                    padding: 0.03rem 0.16rem;
                    color:#838383;
                    font-size: 0.14rem;
                    border-radius: 0.02rem;
                    border:0.01rem solid #979797;
                    margin-right:0.12rem;
                    cursor: pointer;
                }
            }
            .table_right{
                p{
                    &:nth-child(2){
                        background:#9a86db;
                        color:#fff;
                        border:0.01rem solid #9a86db;
                    }
                    &:nth-child(3){
                        background:#B8C1CE;
                        color:#fff;
                        border:0.01rem solid #B8C1CE;
                    }
                }
            }
        }
        .tiaozheng_mubiao{
            height:100%;
            width:100%;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: auto;
            margin: 0;
            z-index:999;
            background: rgba(0,0,0,0.8);
            .tiaozheng_box{
                height:9rem;
                width:14.46rem;
                border-radius: 0.04rem;
                background:#fff;
                position: relative;
                left: 50%;
                top:50%;
                margin-left: -7.23rem;
                margin-top: -4.5rem;
                .tiaozheng_con{
                    padding-top:0.63rem;
                    width:12.46rem;
                    margin: 0 auto;
                    .tiaozheng_tit{
                        font-size: 0.18rem;
                        color:#1A2533;
                        margin-bottom: 0.28rem;
                        text-align: center;
                    }
                    .tiaozheng_tit_right{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        p{
                            color:#4C4A4D;
                            &:nth-child(1){
                                font-size: 0.14rem;
                            }
                            &:nth-child(2){
                                font-size: 0.1rem;
                            }
                        }
                    }
                    .table{
                        height:0.69rem;
                        padding: 0 0.21rem;
                        border:0.01rem solid #888;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top:0.2rem;
                        p{
                            padding: 0.03rem 0.16rem;
                            color:#838383;
                            font-size: 0.14rem;
                            border-radius: 0.02rem;
                            border:0.01rem solid #979797;
                            margin-right:0.12rem;
                            cursor: pointer;
                            &:nth-child(1){
                                background:#9a86db;
                                color:#fff;
                                border:0.01rem solid #9a86db;
                            }
                            // &:nth-child(2){
                            //     background:#9a86db;
                            //     color:#fff;
                            //     border:0.01rem solid #9a86db;
                            // }
                            &:nth-child(3){
                                background:#B8C1CE;
                                color:#fff;
                                border:0.01rem solid #B8C1CE;
                            }
                        }
                    }
                    .table_con{
                        height:6rem;
                        overflow-y:auto;
                        &::-webkit-scrollbar {
                            width: 0.2rem;
                            height: 0.08rem;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 0.12rem;
                            border: 0.06rem solid rgba(0, 0, 0, 0);
                            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            box-shadow: 0.08rem 0 0 #4A4A4A inset;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .box {
            width:100%;
        }
    }
}
.laoye-tbody{
    border:0.01rem solid #888888;
    width:100%;
    border-collapse:collapse;
    tr{
        td{
            height:0.49rem;
            width:13%;
            text-align: center;
            line-height: 0.49rem !important;
            font-size: 0.14rem;
            color:#4C4A4D;
            position: relative;
        }
    }
}
.tiao_bumen{
    display: flex;
    align-items: center;
    justify-content:center;
    .xuanze{
        height:3.54rem;
        width:7rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin: 0.2rem 0;
        position: relative;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            position: relative;
            i{
                position: absolute;
                top:0.09rem;;
                left: 0.12rem;
            }
            input{
                height:0.3rem;
                width:6rem;
                border:none;
                background:#fff;
                padding: 0 0.33rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            // justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#9a86db;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .nei_box{
            width:6.6rem;
            margin: 0 auto;
            min-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:6.4rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        .img2{
                            height:0.13rem;
                            width:0.13rem;
                            margin: 0 0.1rem;
                        }
                        p{
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            .el-icon-search{
                position: absolute;
                top:0.22rem;
                left:0.34rem;
            }
            .el-tree{
                margin-top:0.2rem;
                padding: 0 0.21rem;
            }
            .custom-tree-node{
                display: flex;
                align-items: center;
                img{
                    height:0.14rem;
                    width:0.15rem;
                    display: inline-block;
                    margin-right:0.14rem;
                }
                font-size: 0.12rem;
                color:#1A2533;
            }
        }
    }
    .xuanze_left,.xuanze_right{
        width:49%;
        height:3.54rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin-top: 0.2rem;
        position: relative;
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            position: relative;
            i{
                position: absolute;
                top:0.09rem;;
                left: 0.19rem;
            }
            input{
                height:0.3rem;
                width:2.74rem;
                border:none;
                background:#fff;
                padding: 0 0.33rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            // justify-content: left;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#9a86db;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .quanxuan{
            display: flex;
            align-items: center;
            margin-left: 0.24rem;
            img{
                height:0.16rem;
                width:0.16rem;
                margin-right: 0.08rem;
                cursor: pointer;
            }
            span{
                color:#1A2533;
                font-size: 0.12rem;
            }
        }
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        // justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        .img2{
                            height:0.16rem;
                            width:0.16rem;
                            margin-right: 0.08rem;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .xuanze_right{
        .nei_box{
            .liebiao{
                ul{
                    li{
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}
.foote{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.21rem;
    p{
        margin: 0 0.23rem;
        height:0.43rem;
        width:1.36rem;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        line-height: 0.43rem;
        text-align: center;
        font-weight: bold;
        cursor:pointer;
    }
    .queding{
        color:#fff;
        background: #9a86db;
    }
    .quxiao{
        color: #888888;
        border:0.01rem solid #888888;
        background: #fff;
    }
}
</style>
